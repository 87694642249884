import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { stealCoinsApi } from "../../../api/apiFunctions";
import { IInternalChatMessage, IUserModel, IUserSafeModel } from "../../../types/types";
import { appConfig } from "../../../app/appConfig";
import { formatTimeHHMMSS } from "../../../utils/time";
import InternalChat from "../InternalChat/InternalChat";
import { getFromStorage } from "../../../utils/imageUtil";
import { getStringAsSpan } from "../../../utils/htmlHelper";

interface ThiefProps {
  user: IUserModel;
  setUser: (userModel: IUserModel) => void;
  thief: IUserSafeModel | undefined;
}

const Thief = ({ user, setUser, thief }: ThiefProps) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [thiefCooldownRemaining, setThiefCooldownRemaining] = useState<number>(0);
  const [scoutingClicked, setScoutingClicked] = useState<boolean>(false);
  const [stealClicked, setStealClicked] = useState<boolean>(false);
  const [messages, setMessages] = useState<IInternalChatMessage[]>([]);

  const thiefCooldown = useMemo<number>(() => {
    return Number(appConfig.thiefCooldown);
  }, [appConfig.thiefCooldown])

  const calculateCooldown = () => {
    const nowSeconds = new Date().getTime() / 1000;
    const lastStealFromAtSeconds = new Date(user.lastStealFromAt).getTime() / 1000;
    const difference = nowSeconds - lastStealFromAtSeconds;
    if (difference >= Number(thiefCooldown)) {
      setThiefCooldownRemaining(0);
    } else {
      setThiefCooldownRemaining(Math.round(thiefCooldown - difference));
    }
  }

  const sendMessage = (text: string, incoming: boolean) => {
    setMessages([
      ...messages,
      {
        text: text,
        incoming: incoming
      }
    ])
  }

  const stealCoins = () => {
    stealCoinsApi().then(result => {
      setUser(result.thief);
      sendMessage(t('app.thief.chat.stealingResult', {
        coins: getStringAsSpan(Math.round(result.victim.lostCoins).toString(), "text-gold text-xl font-semibold"),
        thiefPowerLevel: result.thief.thiefPower,
        victimUsername: getStringAsSpan(result.victim.username ?? `${result.victim.firstName ?? t('app.stats.fallBackName')} ${result.victim.lastName ?? ""}`, "text-red-500 text-xl font-semibold"),
        victimProtectionLevel: result.victim.thiefProtection
      }), true);
    }).catch(() => {
      sendMessage(t('app.thief.chat.error'), true);
    }).finally(() => {
      setScoutingClicked(false);
      setStealClicked(false);
      setLoading(false);
    })
  }

  useEffect(() => {
    if (stealClicked) {
      stealCoins();
    }
  }, [stealClicked]);

  const onStealClick = () => {
    if (thiefCooldownRemaining > 0) return;

    setLoading(true);
    sendMessage(t('app.thief.chat.sendTroops'), false);
    setStealClicked(true);
  }

  const onScoutingClick = () => {
    sendMessage(t("app.thief.chat.sendSpies"), false);
    setScoutingClicked(true);
    setLoading(true);
  }

  const onRefuseClick = () => {
    sendMessage(t("app.thief.chat.refuse"), false);
    setScoutingClicked(false);
  }

  useEffect(() => {
    const messages: IInternalChatMessage[] = [
      // {
      //   text: `<span className="text-yellow-400 text-xl select-text"" ">${t('app.thief.chat.thiefNotification')}</span>`,
      //   incoming: true
      // }
    ]

    if (thief && user.lostCoins && user.lostCoins > 0) {
      setMessages(
        [...messages,
          {
            text: t("app.thief.chat.sadGreetings",
              {
                username: getStringAsSpan(user.username ?? `${user.firstName ?? t('app.stats.fallBackName')} ${user.lastName ?? ""}`, "text-green-500 text-xl font-semibold"),
                thief: getStringAsSpan(thief.username, "text-red-500 text-xl font-semibold"),
                thiefPower: thief.thiefPower,
                lostCoins: getStringAsSpan(Math.round(user.lostCoins).toString(), "text-gold text-xl font-semibold"),
              }
            ),
            incoming: true
          }
        ]
      );
    } else {
      setMessages(
        [...messages,
          {
            text: t("app.thief.chat.greetings",
              {
                value: getStringAsSpan(user.username ?? `${user.firstName ?? t('app.stats.fallBackName')} ${user.lastName ?? ""}`, "text-green-500 text-xl font-semibold")
              }
            ),
            incoming: true
          }
        ]
      );
    }
  }, []);

  useEffect(() => {
    if (scoutingClicked) {
      const interval = setInterval(() => {
        sendMessage(t("app.thief.chat.spiesReturned"), true);
        setLoading(false);
      }, 1000)

      return () => clearInterval(interval);
    }
  }, [scoutingClicked]);

  useEffect(() => {
    const interval = setTimeout(() => {
      calculateCooldown();
    }, 1000)

    return () => clearInterval(interval);
  }, [thiefCooldownRemaining]);

  useEffect(() => {
    calculateCooldown();
  }, [user]);

  return (
    <>
      <div className={'overflow-y-scroll p-4 grow flex flex-col gap-4 items-center'}>
        <div className={"rounded-2xl overflow-hidden w-fit min-h-fit"}>
          <img
            className={'h-full w-fit max-h-64 object-contain'}
            src={getFromStorage("thief_banner.png")}
            alt=""
          />
        </div>
        <InternalChat messages={messages} />
      </div>
      <div className={'w-full p-2 min-h-16 content-center shadow-md overflow-hidden appearance-bottom'}>
        {
          loading ?
            <button className={"bg-graySecondary rounded-2xl py-3 px-6 w-full h-12 overflow-hidden"} />
            :
            thiefCooldownRemaining > 0 ?
              (
                <button className={"bg-graySecondary rounded-2xl py-3 px-6 w-full h-12 overflow-hidden"}>
                  {formatTimeHHMMSS(thiefCooldownRemaining)}
                </button>
              )
              :
              scoutingClicked ? (
                  <div className={'flex gap-2'}>
                    <button
                      className={`w-full rounded-2xl  bg-amber-900 py-3 px-6 h-12 block relative overflow-hidden clickable font-semibold text-xl`}
                      onClick={onStealClick}
                    >
                      <span>{t("app.thief.steal")}</span>
                    </button>
                    <button
                      className={`w-full rounded-2xl  bg-green-950 py-3 px-6 h-12 block relative overflow-hidden clickable font-semibold text-xl`}
                      onClick={onRefuseClick}
                    >
                      <span>{t("app.thief.refuse")}</span>
                    </button>
                  </div>
                ) :
                (
                  <button
                    className={`w-full rounded-2xl  bg-amber-900 py-3 px-6 h-12 block relative overflow-hidden clickable font-semibold text-xl`}
                    onClick={onScoutingClick}
                  >
                    <span>{t("app.thief.chat.sendSpies")}</span>
                  </button>
                )
        }
      </div>
    </>
  );
};

export default Thief;
