import { useEffect, useMemo, useState } from "react";
import { IAppImage, roleImages } from "../../../constants/raceImages";
import { useOutletContext } from "react-router-dom";
import {
  BattleModel,
  BattleResultModel,
  BattleStage,
  ErrorResponse,
  GameOption,
  IContext,
  IInternalChatMessage,
} from "../../../types/types";
import { mapRace } from "../../../utils/transformation";
import InternalChat from "../InternalChat/InternalChat";
import { calcHp, generateMessage } from "../../../utils/battle";
import FightCharacter from "./FightCharacter";
import FightWarningModal from "./FightWarningModal";
import { useTranslation } from "react-i18next";
import FightStartScreen from "./FightStartScreen";
import { formatTimeMMSS } from "../../../utils/time";
import FightSelectOption from "./FightSelectOption";
import { finishBattleApi, getActiveBattleApi, makeMoveApi, startBattleApi } from "../../../api/apiFunctions";
import { appConfig } from "../../../app/appConfig";
import { getFromStorage } from "../../../utils/imageUtil";

const Fight = ({
    isFightModalOpen,
    setIsFightModalOpen,
    warning,
    setWarning,
    showWarning,
    setShowWarning,
  }: {
    isFightModalOpen: boolean;
    warning: string;
    setWarning: (val: string) => void;
    setIsFightModalOpen: (val: boolean) => void;
    showWarning: boolean;
    setShowWarning: (val: boolean) => void;
  }) => {

    const [battleStage, setBattleStage] = useState<BattleStage>(BattleStage.NOT_STARTED);
    const [gameOption, setGameOption] = useState<GameOption | undefined>();
    const { t } = useTranslation();
    const [messages, setMessages] = useState<IInternalChatMessage[]>([
      { text: t('app.fight.battleStarted'), incoming: true },
    ]);
    const { user, setUser } = useOutletContext<IContext>();
    const [battleTimeLeft, setBattleTimeLeft] = useState<number>(-1);
    const [bet, setBet] = useState<number>(1)
    const hp = 100;
    const [enemyHpLeft, setEnemyHpLeft] = useState(hp);
    const [heroHpLeft, setHeroHpLeft] = useState(hp);
    const [mainCharacterIcon, setMainCharacterIcon] = useState<IAppImage | null>(null);
    const [battleResultMessage, setBattleResultMessage] = useState<string>("");
    const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
    const [battleModel, setBattleModel] = useState<BattleModel | undefined>();
    const [battleResult, setBattleResult] = useState<BattleResultModel | null>();
    const [fightCooldownRemaining, setFightCooldownRemaining] = useState<number>(0);
    const [loading, setLoading] = useState(false);

    const fightCooldown = useMemo<number>(() => {
      return Number(appConfig.fightCooldown);
    }, [appConfig.fightCooldown])

    const calculateCooldown = () => {

      const nowSeconds = new Date().getTime() / 1000;
      const lastFightFinishedAtSeconds = new Date(user.lastBattleStartedAt).getTime() / 1000;
      const difference = nowSeconds - lastFightFinishedAtSeconds;

      if (difference >= Number(fightCooldown)) {
        setFightCooldownRemaining(0);
      } else {
        setFightCooldownRemaining(Math.round(fightCooldown - difference));
      }
    }

    const calculateBattleDuration = () => {

      const nowSeconds = new Date().getTime() / 1000;
      const battleDeadline = new Date(battleModel?.started!).getTime() / 1000 + 179;
      const difference = battleDeadline - nowSeconds;

      if (battleTimeLeft === 0) {
        onTimeExpired();
      } else if (difference >= 0) {
        setBattleTimeLeft(Math.round(difference));
      }
    }

    const resetBattleModel = () => {
      setBattleModel(undefined);
    };

    const closeFight = async () => {
      if (!battleModel) return;

      try {
        if (BattleStage.STARTED) {
          const battleResult = await finishBattle();
          handleFinishedBattle(battleResult);
        } else {
          setWarning("");
          setIsFightModalOpen(false);
        }
      } catch (error: any) {
        const errCode = error.code;
        if (errCode === 4) {
          setWarning("");
          setIsFightModalOpen(false);
        } else {
          setWarning(t(`app.errorCodes.${error.code}`).toString());
          setShowWarning(true);
        }
      }
    }

    const closeResults = () => {
      setBattleStage(BattleStage.NOT_STARTED);
      setIsFightModalOpen(false);
    }

    const onTimeExpired = () => {
      if (!battleModel) return;
      setBattleTimeLeft(-1);
      closeFight().finally();
    };

    const getActiveBattle = async (): Promise<BattleModel | null> => {
      try {
        const activeBattleResponse = await getActiveBattleApi();
        return activeBattleResponse.battle;
      } catch (error: any) {
        throw error.response.data.error;
      }
    }

    const startBattle = async () => {
      try {
        const startBattleResponse = await startBattleApi({ bet: bet.toString() });
        return startBattleResponse.battle;
      } catch (error: any) {
        throw error.response.data.error;
      }
    }

    const makeMove = async () => {
      try {
        if (!battleModel) throw new Error(/*TODO*/);
        const makeMoveResponse = await makeMoveApi({ battleId: battleModel.id, option: Number(gameOption) });
        return makeMoveResponse.move;
      } catch (error: any) {
        throw error.response.data.error;
      }
    }

    const finishBattle = async () => {
      try {
        if (!battleModel) throw new Error(/*TODO*/);
        const finishBattleResponse = await finishBattleApi({ battleId: battleModel.id });
        return finishBattleResponse.result;
      } catch (error: any) {
        throw error.response.data.error;
      }
    }

    const finishActiveBattleIfNotNull = async (activeBattle: BattleModel | null) => {
      try {
        if (activeBattle) {
          setBattleModel(activeBattle);
          const finishBattleResponse = await finishBattleApi({ battleId: activeBattle.id });
          return finishBattleResponse.result;
        }
        return null;
      } catch (error: any) {
        const errorData = error.response.data.error;
        if (errorData.code !== 4) {
          throw errorData;
        }
      }
    }

    const handleFinishedBattle = (battleResultModel: BattleResultModel | null) => {
      const reward = Number(battleResultModel?.reward);
      switch (battleResultModel?.result) {
        case -1:
          setHeroHpLeft(0);
          break;
        case 1:
          setEnemyHpLeft(0);
          if(reward && reward > 0) {
            setUser({...user, ...{coins: user.coins + reward}})
          }
          break;
        case 0:
          if(reward && reward > 0) {
            setUser({...user, ...{coins: user.coins + reward}})
          }
          break;
      }
      setBattleResult(battleResultModel);
      setBattleStage(BattleStage.FINISHED);
      setBattleResultMessage(generateBattleResultMessage(battleResultModel?.result) ?? "");
    }

    const onStartClick = () => {
      startBattle().then(response => {
        setUser({
          ...user, ...{
            lastBattleStartedAt: new Date().toString(), coins: Math.round(user.coins - Number(response.bet))
          }
        });
        setBattleModel(response);
        setBattleStage(BattleStage.STARTED);
      }).catch((error: ErrorResponse) => {
        setWarning(t(`app.errorCodes.${error.code}`).toString());
        setShowWarning(true);
      });
    };

    const handleGameOptionClick = (option: GameOption) => {
      setGameOption(option);
    };

    const addMessage = (text: string) => {
      setMessages((prev) => [
        ...prev,
        {
          text: text,
          incoming: true,
        },
      ]);
    }

    const handleGameOptionSubmit = () => {
      if (battleModel && gameOption) {
        makeMove().then(moveResult => {

          switch (moveResult.result.toString()) {
            case '1': {
              const response = generateMessage(responses.hit);
              addMessage(response);
              setEnemyHpLeft((curr) => calcHp(curr));
              break;
            }
            case '-1': {
              const response = generateMessage(responses.miss);
              addMessage(response);
              setHeroHpLeft((curr) => calcHp(curr));
              break;
            }
            default: {
              setHeroHpLeft((curr) => calcHp(curr));
              setEnemyHpLeft((curr) => calcHp(curr));
              addMessage(t('app.fight.draw'));
              break;
            }
          }

          setBattleModel({ ...battleModel, ...{ turn: moveResult.turn, score: battleModel.score + moveResult.result } });
        }).catch(error => {
          setWarning(t(`app.errorCodes.${error.code}`));
          setShowWarning(true);
        });
      }
    };

    const responses = useMemo(() => {
      if (battleModel) {
        const enemyId = battleModel.enemy + 1;

        return {
          miss: t(`app.enemies.lvl${user.level}.${enemyId}.player_miss`, { returnObjects: true }) as string [],
          hit: t(`app.enemies.lvl${user.level}.${enemyId}.player_hits_enemy`, { returnObjects: true }) as string [],
          win: t(`app.enemies.lvl${user.level}.${enemyId}.enemy_dies`, { returnObjects: true }) as string [],
          loose: t(`app.enemies.lvl${user.level}.${enemyId}.player_dies`, { returnObjects: true }) as string [],
        }
      }
      return { miss: [''], hit: [''], win: [''], loose: [''] };
    }, [battleModel]);

    const checkGameIsOver = () => {
      return battleModel && battleModel.turn >= battleModel.maxTurns;
    }

    const generateBattleResultMessage = (result?: number) => {
      switch (result) {
        case -1:
          return generateMessage(responses.loose);
        case 1:
          return generateMessage(responses.win);
        default:
          return t('app.fight.drawFight');
      }
    }

    const ensureLastBattleFinished = async () => {
      const activeBattle = await getActiveBattle();
      await finishActiveBattleIfNotNull(activeBattle);
    }

    useEffect(() => {
      if (checkGameIsOver()) {
        finishBattle().then(result => {
          handleFinishedBattle(result);
        }).catch(error => {
          setWarning(t(`app.errorCodes.${error.code}`));
          setShowWarning(true);
        })
      }

      setGameOption(undefined);
    }, [battleModel]);

    useEffect(() => {
      if (battleStage === BattleStage.NOT_STARTED) {
        setIsFinishModalOpen(false);
        resetBattleModel();
        setShowWarning(false);
        setEnemyHpLeft(hp);
        setHeroHpLeft(hp);
        setIsFinishModalOpen(false);
        setBattleResultMessage("");
      }
      if (battleStage === BattleStage.STARTED) {
        setMessages([{ text: t('app.fight.battleStarted'), incoming: true }]);
        setWarning(
          "app.fight.ifYouLeave"
        );
      }
      if (battleStage === BattleStage.FINISHED) {
        setWarning("");
        setIsFinishModalOpen(true);
      }
    }, [battleStage]);

    useEffect(() => {
      if (isFightModalOpen) setBattleStage(BattleStage.NOT_STARTED);
    }, [isFightModalOpen]);

    useEffect(() => {
      if (user) {
        const roleImageObj: IAppImage | undefined = roleImages.find(
          (image) => mapRace(image.value) === mapRace(user.race)
        );
        setMainCharacterIcon(roleImageObj ?? null);
      }
    }, [user, mainCharacterIcon]);

    useEffect(() => {
      const interval = setTimeout(() => {
        calculateBattleDuration();
      }, 1000)

      return () => clearInterval(interval);
    }, [battleTimeLeft]);

    useEffect(() => {
      const interval = setTimeout(() => {
        calculateCooldown();
      }, 1000)

      return () => clearInterval(interval);
    }, [fightCooldownRemaining]);

    useEffect(() => {
      calculateCooldown();
      calculateBattleDuration();
    }, [user]);

    useEffect(() => {
      ensureLastBattleFinished().catch(error => {
        setWarning(t(`app.errorCodes.${error.code}`));
        setShowWarning(true);
      })
    }, []);

    if (battleStage === BattleStage.NOT_STARTED || !battleModel) {
      return (
        <>
          <FightStartScreen
            bet={bet}
            userCoins={user.coins}
            miningPower={user.miningPower}
            setBet={setBet}
            fightCooldownRemaining={fightCooldownRemaining}
            loading={loading}
            onStartClick={onStartClick}
          />
          {warning && showWarning && (
            <FightWarningModal>
              <div className="flex flex-col h-full justify-between">
                <span className="text-xl m-auto">{t(warning)}</span>
                <div>
                  <button
                    onClick={() => {
                      setWarning("");
                      setShowWarning(false);
                    }}
                    className="w-full rounded-xl bg-red-700 p-2 mb-2"
                  >
                    {t("app.fight.close")}
                  </button>
                </div>
              </div>
            </FightWarningModal>
          )}
        </>
      );
    }

    return (
      <div className={"p-2 grow relative flex flex-col justify-between overflow-auto"}>
        <div className="max-h-[calc(100%-100px)] grow bg-grayPrimary rounded-xl p-2 ">
          <div className={"flex justify-between items-center"}>
            <FightCharacter
              image={mainCharacterIcon?.getImage()}
              alt={mainCharacterIcon?.alt}
              hp={hp}
              hpLeft={heroHpLeft}
            />
            <div className="flex flex-col justify-center items-center text-center">
              <span>{formatTimeMMSS(battleTimeLeft)}</span>
              <span className="text-4xl">VS</span>
              <span>{t("app.fight.movesLeft")}:</span>
              {battleModel.enemy && (
                <span>
                {battleModel.turn}/{battleModel.maxTurns}
              </span>
              )}
            </div>
            <FightCharacter
              alt={"app.unknown"}
              hp={hp}
              hpLeft={enemyHpLeft}
              image={getFromStorage(`battle/lvl${user.level}/${battleModel.enemy + 1}.png`)}
            />
          </div>
          {/* controls size of the chat */}
          <div className="h-[calc(100%-150px)] overflow-auto my-3">
            <InternalChat messages={messages} />
          </div>
        </div>
        <FightSelectOption
          battleStage={battleStage}
          gameOption={gameOption}
          handleGameOptionClick={handleGameOptionClick}
          handleGameOptionSubmit={handleGameOptionSubmit}
        />
        {battleStage === BattleStage.FINISHED && isFinishModalOpen && (
          <FightWarningModal>
            <div className="flex flex-col h-full justify-between">
              <div className="text-xl text-center m-auto">
              <span className="text-3xl block mb-4">
                {t("app.fight.gameOver")}
              </span>
                {
                  battleResult && (
                    <>
                      {battleResultMessage}
                      <br />
                      <span className={'mt-2 text-gold'}>
                        {'+' + battleResult.reward}
                      </span>
                    </>
                  )
                }
              </div>
              <button
                onClick={closeResults}
                className="w-full rounded-xl bg-green-950 p-2 mt-2"
              >
                {t("app.fight.close")}
              </button>
            </div>
          </FightWarningModal>
        )}
        {warning && showWarning && (
          <FightWarningModal>
            <div className="flex flex-col h-full justify-between">
              <span className="text-xl m-auto">{t(warning)}</span>
              <div>
                <button
                  onClick={() => closeFight()}
                  className="w-full rounded-xl bg-red-700 p-2 mb-2"
                >
                  {t("app.fight.closeAnyway")}
                </button>
                <button
                  onClick={() => setShowWarning(false)}
                  className="w-full rounded-xl bg-green-950 p-2"
                >
                  {t("app.fight.continue")}
                </button>
              </div>
            </div>
          </FightWarningModal>
        )}
      </div>
    );
  }
;

export default Fight;
