import { useTranslation } from "react-i18next";

interface IFightCharacter {
  alt?: string,
  image?: string,
  hp: number,
  hpLeft: number,
}

const FightCharacter = ({ alt, image, hp, hpLeft }:IFightCharacter) => {
  const { t } = useTranslation();

  return (
    <div className={`relative flex flex-col bg-graySecondary p-2 rounded-xl shadow-goldSecondary`}>
      <img
        className={"h-[100px] w-[80px] object-cover rounded-xl object-center"}
        alt={t(`${alt ? alt : 'icon_of_character'}`)}
        src={image}
      />
      <div
        className={
          "mt-2 w-full h-4 relative bg-[#800020] rounded-full overflow-hidden align-self-end flex items-center justify-center shadow-green"
        }
      >
        <span className="z-10">
          {hpLeft}/{hp}
        </span>
        <div
          className={`h-full bg-[#228B22] absolute left-0`}
          style={{ width: `${(hpLeft / hp) * 100}%` }}
        ></div>
      </div>
    </div>
  );
};

export default FightCharacter;
